<template>
    <div class="box">
        <Header type="expert" :navList="navList" :navCurrentId="navCurrentId" :logo="logoUrl"></Header>
        <div class="container">
            <!-- 面包屑 -->
            <div class="flex pb5 x-left flex-wrap">
                <div class="color999">您所在的位置：</div>
                <a-breadcrumb class="t-l">
                    <a-breadcrumb-item><router-link class="color999" :to="{path:'/'}">首页</router-link></a-breadcrumb-item>
                    <a-breadcrumb-item><router-link class="color999" :to="{path:'/expert/index'}">医咖秀</router-link></a-breadcrumb-item>
                    <a-breadcrumb-item><router-link class="color999" :to="{path:'/expert/committee'}">学科主委</router-link></a-breadcrumb-item>
                    <a-breadcrumb-item>{{queryParams.name}}</a-breadcrumb-item>
                </a-breadcrumb>
            </div>
            <div class="content">
                <h1>{{queryParams.simple_name}}</h1>
                <div class="card-list mt40 flex x-left">
                    <a-card style="width: 380px" class="card-item" v-for="(item,index) in listData" :key="index" @click="handleTap(item.period_id)">
                        <div class="t-c strong fs22">{{item.committee_name_show.split('分会')[0]}}分会</div>
                        <div class="t-c strong fs22">{{item.committee_name_show.split('分会')[1]}}</div>
                        <div class="t-c fs14" style="color:red;">
                            <template v-if="item.BeginTime && item.EndTime">
                                {{ item.BeginTime }} ~ {{ item.EndTime }}
                            </template>
                            <span v-else>&nbsp;</span>
                        </div>
                        <div class="key-name mt20">委员数量</div>
                        <div class="fs22 strong">{{item.MemberCount || 0}}人</div>
                    </a-card>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import {expertNavList, exportLogo} from "@/config/const";
    import Header from '@/components/layout/layout-header';
    import Footer from '@/components/layout/layout-footer';

    export default {
        name: "subject-search",
        components : {
          Header , Footer
        },
        data(){
            return{
                logoUrl : exportLogo,
                navList : expertNavList,//顶部菜单
                navCurrentId : 4,//当前页面菜单对应下标
                queryParams : {
                    name : '',//学科名
                    id : 0,//id
                    simple_name : '',//学会名称
                },
                listData : []
            }
        },
        created() {
          this.queryParams = this.$route.query;
          this.getList();
        },
        methods : {
            getList(){
                let { id } = this.queryParams;
                this.request.post('PeriodList',{committee_id: id}).then(res => {
                    this.listData = res.data;
                })
            },
            handleTap(period_id){
                let { name , id , simple_name } = this.queryParams;
                this.$router.push({
                    path : '/expert/subject-details',
                    query : {
                        name,id,period_id
                    }
                })
            }
        }
    }
</script>

<style scoped lang="less">
    h1{
        font-weight: bold;
    }
    .content{
        min-height: 600px;
    }
    .card-list{
        flex-wrap: wrap;
    }
    .card-item{
        margin-right: 30px;
        margin-bottom: 30px;
        cursor: pointer;
        &:nth-child(3n){
            margin-right: 0;
        }
        /deep/.ant-card-body{
            padding: 20px 10px;
        }
    }
    @media screen and (max-width:768px){
        .container{
            padding: 10px;
            .content{
                .card-item{
                    margin-right: 20px;
                    width: calc((100% - 20px) / 2)!important;
                    &:nth-child(2n+2){
                        margin-right: 0;
                    }
                }
            }
        }
    }
    @media screen and (max-width:550px){
        .container{
            .content{
                .card-item{
                    margin-right: 0;
                    width: 100%!important;
                }
            }
        }
    }
</style>